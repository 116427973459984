import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];

  updateLink(event) {
    const inputElement = event.currentTarget;
    const paramName = inputElement.name;
    const paramValue = inputElement.value;

    const linkElement = this.linkTarget;
    const url = new URL(linkElement.href);
    const params = new URLSearchParams(url.search);

    // クエリパラメータを更新
    if (paramValue) {
      params.set(paramName, paramValue);
    } else {
      params.delete(paramName); // 値が空の場合は削除
    }

    // リンクの href を更新
    linkElement.href = `${url.pathname}?${params.toString()}`;
  }


  updateAndClick(event) {
    this.updateLink(event);
    this.linkTarget.click();
  }
}
