import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
  };
  static targets = ['file', 'pictureId', 'message'];

  async upload() {
    try {
      const file = this.fileTarget.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      // 非同期でやりたいときは以下のようにしておく
      // const response = await fetch(this.urlValue, {
      //   method: 'POST',
      //   headers: {
      //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      //   },
      //   body: formData,
      // });

      // 動作テスト時ではBGFileStoreにアップロードを行わないため、ダミーのresponseを用意している
      const response = {picture_id: '12345', message: 'テストメッセージ'};

      // TODO: ダミーのresponseがあるのでelseなどには入らないが手続きで使う用に残している
      if (response) {
        const data = response;

        // 非同期でやりたい(FileStoreにアップロードした)ときは以下のようにしておく
        // const data = await response.json();
        this.pictureIdTarget.value = data.picture_id;

        const event = new CustomEvent('file-upload:success');
        window.dispatchEvent(event);
      } else {
        const data = response;

        // 非同期でやりたい(FileStoreにアップロードした)ときは以下のようにしておく
        // const data = await response.json();
        this.messageTarget.textContent = data.message;
        this.pictureIdTarget.value = '';
      }
    } catch (error) {
      const data = response;

      // 非同期でやりたい(FileStoreにアップロードした)ときは以下のようにしておく
      // const data = await response.json();
      this.messageTarget.textContent = data.message;
      this.pictureIdTarget.value = '';
      alert('通信エラーが発生しました。');
    }
  }
}
