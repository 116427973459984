import {Controller} from '@hotwired/stimulus';
import {enableDraggableModal} from 'javascripts/global/lib/fetch_modal_picture/enable_draggable_modal.js';
import {enableRichPicture} from 'javascripts/global/lib/fetch_modal_picture/enable_rich_picture.js';
import {enableResizableModal} from 'javascripts/global/lib/fetch_modal_picture/enable_resizable_modal.js';

export default class extends Controller {
  // クリックしたらshowが呼ばれる
  show() {
    enableDraggableModal();
    enableResizableModal();
    enableRichPicture();
  }
}
